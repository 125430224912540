import NextLink from 'next/link';

import { m } from 'framer-motion';

import { Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PageNotFoundIllustration } from '@hui/assets';
import { Page } from '@hui/components';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Page pageConfig={{ title: '404 Page Not Found', path: '', permission: '' }}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div>
            <Typography variant="h3" paragraph>
              ページが見つかりません
            </Typography>
          </m.div>

          <m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              左のメニューから別のページへ移動してください。
            </Typography>
          </m.div>

          <m.div>
            <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>

          <NextLink href="/" passHref>
            <Button size="large" variant="contained">
              トップに戻る
            </Button>
          </NextLink>
        </ContentStyle>
      </Container>
    </Page>
  );
}
